import request from "@/utils/request";
import method from "@/const/request/requestMethod";


export function getOptions(key) {
    return request({
        url: "/common/options/"+key,
        method: method.GET
    })
}



